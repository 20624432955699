import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NavbarSection } from '../../models/navbar-section.model';

@Component({
  selector: 'app-dropdown-navbar-section',
  templateUrl: './dropdown-navbar-section.component.html',
  styleUrls: ['./dropdown-navbar-section.component.scss']
})
export class DropdownNavbarSectionComponent implements OnInit {

  @ViewChild('dropdown') dropdown: ElementRef;

  @Input() options: NavbarSection[];

  public selectedOption: string;

  constructor() {
  }

  ngOnInit() {
    this.selectedOption = this.options[0].title;

    document.documentElement.style.setProperty('--dropdown-nav-count', `${this.options.length}`);

    window.addEventListener('scroll', () => {
      Array.from(this.options).forEach(item => {
        this.setSectionActive(item);
      });
    });
  }

  public toggleDropdown(): void {
    this.dropdown.nativeElement.classList.toggle('open');
  }

  private setSectionActive(item): void {
    const el = document.getElementById(`${item.id}`);
    if (el === null) { return; }
    if (el.getBoundingClientRect().top <= 150) {
      this.selectedOption = item.title;
    }
  }

  private selectSection(id: string, extraSpace: number): void {
    const offsetTopValue = document.getElementById(`${id}`).offsetTop;
    window.scrollTo(0, (offsetTopValue - extraSpace));
  }

  dropdownSection(selectedOption: number) {
    this.options.forEach(option => {
      if (option.value === selectedOption) {
        this.selectedOption = option.title;
        this.selectSection(option.id, 150);
        this.toggleDropdown();
      }
    });
  }

}
