import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { SharedModule } from '../shared/shared.module';

// Routes
import { BASE_ROUTES } from './base.routes';

// Components
import { BaseComponent } from './base.component';
import { HomeComponent } from './components/home.component';
import { NotFoundComponent } from '../error-page/not-found/not-found.component';

import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@NgModule({
  declarations: [
    BaseComponent,
    HomeComponent,
    NotFoundComponent,
  ],
  imports: [
    CommonModule,
    BASE_ROUTES,
    SharedModule
  ],
  exports: [],
  providers: [
    {
        provide: 'externalUrlRedirectResolver',
        useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        {
            window.location.href = (route.data as any).externalUrl;
        }
    },
    {
      provide: 'assetUrlResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      {
        console.log(route.data.fileName);
          window.location.href =((route.data.fileName as string));
      }
  },
],
})
export class BaseModule {}
