import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken =
      'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MTA5OTE2NjEsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NqbnZ6c2lndzFyc2IwMWlqZDZtd241aWUvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMmYxZTljYzItM2E0NC00Mjk4LThlNzAtZTlhNGRhOTE3MWJjIiwianRpIjoiY2trMnV1OW4weWF5OTAxeG02Y3IyOGV1ZCJ9.C06Z215YgK6U8LkpBJEFe6x1VrUf8Ca7ehDab2JL2tV-cOmrmGyqvRuvSvQl5TLYxhG_3yuG3AVVvL1gt5VqQjwIPJu-aZNkmotIyFkATgMeC4PpAZLb8H0vw_C0OxhaWeaWfmNgrZUypjPKEscw2rG2E6yPuIaRuINZ_5VRFPwUV0fp8id-nmKaur5f7TiBUai-_s_MKBwJoiAN_nBhXdg8DYDKMQLmxxf5MQVFuxtjNwhOrTsfxF2KB1NGI8uf2lt15hKsNcflUGRrAuWpn6D6Wn56qhh32QElhVXc3SEKERmzPH6H6mix0wRW6Fiq-wpmC-KztEJ9lzvFALghGh6TSoGuK8ZoSlpqOsLMBFXz2avFkhhxwzb0gcTA-RnLj4hHpNeEFI7fvoEf5hD46y54Xcyy0PBmxk6WRMfD7-_Kv73sP0jKFl-icwEirbcMcUqZg8QCajXkfWVskgRwhOjkJ9XdzGBwITxwfLF8xDczh0BiPZlophucGncnCQWaGhREgDAfq5POJTHUYQPajr_ywUXBpUiysh40Cknd-pLnli3kkV0bQMe1kTuviVl68YzjK_sc68gzO44nqTzg7wwuW4qQb5JFG9LC0xCCvCBm5Fg_L5iV3osiv5nokeaPDGtqRyn7U-Gs8Ine9TUBwpysdwpzoTHWAmxSBLXt2lM';
    const tokenMailer =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7Im5hbWUiOiJ0ZXN0IiwidXNlcm5hbWUiOiJ0ZXN0In0sImlhdCI6MTU0NDY0NzQ1NH0.PByzuVIEAu8mNo-6J2aRk6XN6u74FJl22Ok3q_JuWDU';
    let authHeaders;
    if (!this.isGraphCMS(req.url)) {
      authHeaders = req.clone({
        setHeaders: {
          Authorization: authToken,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    } else if (this.isMailer(req.url)) {
      authHeaders = req.clone({
        setHeaders: {
          Authorization: tokenMailer,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    }

    if (authHeaders) {
      return next.handle(authHeaders);
    } else {
      return next.handle(req);
    }
  }

  isGraphCMS(url: string) {
    return url.search('api-useast.graphcms.com') !== -1;
  }

  isMailer(url: string) {
    const regex = /mail/g;
    return regex.test(url);
  }
}
