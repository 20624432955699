import { AssetsService } from '../../services/assets.service';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentInit
} from '@angular/core';
import { Button } from 'protractor';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterContentInit {
  @ViewChild('navbarContainer') navbarContainer: ElementRef;
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('nav') nav: ElementRef;
  @ViewChild('phoneContainer') phoneContainer: ElementRef;
  @ViewChild('phoneButton') phoneButton: ElementRef;
  @ViewChild('barTop') barTop: any;
  @ViewChild('barBottom') barBottom: any;
  //@ViewChild('barEnd') barEnd: any;


  public logo: string;
  public callServiceOpen = false;

  constructor(private assets: AssetsService, private router: Router) {
    this.assets
      .getAsset('Sv_logo1.svg')
      .subscribe(data => (this.logo = data[0].url));
  }

  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      if (this.navbarContainer.nativeElement.getBoundingClientRect().top <= 0) {
        this.navbar.nativeElement.classList.add('sticky');
      } else {
        this.navbar.nativeElement.classList.remove('sticky');
      }
    });
  }

  ngAfterContentInit(): void {
    const resizeEvent = () => {
      const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
      document.documentElement.style.setProperty(
        '--left-margin',
        `${leftSpace}px`
      );
    };

    window.addEventListener('resize', () => {
      resizeEvent();
    });

    resizeEvent();

    this.phoneButton.nativeElement.addEventListener('click', (e) => {
      this.phoneContainer.nativeElement.classList.toggle('open');
      e.stopPropagation();
    });

    document.addEventListener('click', (e) => {
      // tslint:disable-next-line: triple-equals
      if (e.target != this.phoneButton.nativeElement) {
        this.phoneContainer.nativeElement.classList.remove('open');
      }
    });
  }
  clickMenu(){
    if(this.menu.nativeElement.classList.contains("open"))
      this.closeMenu();
    else
      this.openMenu();
  }
  openMenu() {
    this.updateActiveElement();
    this.menu.nativeElement.classList.add('open');
    this.barTop.nativeElement.classList.add('open');
    this.barBottom.nativeElement.classList.add('open');
    //this.barEnd.nativeElement.classList.add('open');
    document.body.style.overflow = 'hidden';
  }

  closeMenu() {
    this.updateActiveElement();
    this.menu.nativeElement.classList.remove('open');
    this.barTop.nativeElement.classList.remove('open');
    this.barBottom.nativeElement.classList.remove('open');
    //this.barEnd.nativeElement.classList.remove('open');
    document.body.style.overflow = 'auto';
  }

  updateActiveElement() {
    let margin = 0;
    let activeIndex = 0;
    let baseCenter = 0;

    Array.from(document.querySelectorAll('.navbar__link')).forEach(
      (item, i) => {
        if (item.classList.contains('active')) {
          margin = 18;
          activeIndex = i + 1;
          baseCenter = (margin * 3) / 2;

          for (let j = 1; j < activeIndex; j++) {
            baseCenter = baseCenter + margin * 2;
          }
        }
      }
    );

    this.nav.nativeElement.style.setProperty(
      '--triangule-top',
      `${baseCenter - margin}px`
    );
    this.nav.nativeElement.style.setProperty(
      '--triangule-center',
      `${baseCenter}px`
    );
    this.nav.nativeElement.style.setProperty(
      '--triangule-bottom',
      `${baseCenter + margin}px`
    );
  }

  public callServiceEvent(action: boolean): void {
    this.callServiceOpen = action;
    if (action) {
      this.phoneContainer.nativeElement.focus();
    }
  }
}
