import { Injectable } from '@angular/core';
// Necessary to work with GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Model
import { Pagina } from '../models/pages';
// Enums
import { Vistas } from './../enums/vistas';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private apollo: Apollo) {}

  public getPage(vista: Vistas, friendlyId: string, dateIn?: string): Observable<Pagina> {
    return this.apollo
      .watchQuery({
        query: gql`
          query pages($vista: Vistas, $friendlyId: String, $dateIn: Date) {
            paginas(where: { vista: $vista, friendlyId: $friendlyId}) {
              vista
              heroImg {
                url
                alt
              }
              heroRImg {
                url
                alt
              }
              heroTitle
              friendlyId
              heroDescription
              tyc {
                url
              }
              secciones(orderBy: posicion_ASC) {
                idSeccion
                tituloSeccion
                descripcionSeccion
                tituloLinkSeccion
                posicion
                seccionImg {
                  url
                  alt
                }
                asistencias {
                  cardImg {
                    url
                    alt
                  }
                  nombrePublico
                  tituloEnCardDeAsistencia
                  cardDescription
                  displayIndex
                  descripcionModal
                  tyc {
                    url
                  }
                }
                certificaciones {
                  tituloCertificacion
                  descripcionCertificacion
                }
                seccionLinkSubpaginas {
                  thumbnailLinkSubpagina {
                    url
                    alt
                  }
                  tituloLinkSubpagina
                  descripcionLinkSubpagina
                  linkTitle
                  enlaceLinkSubpagina
                  tycSelector
                  titleButton
                  linkButton
                  tycFile {
                    url
                  }
                }
                bulletPoints {
                  iconoBulletPoint {
                    url
                    alt
                  }
                  textoBulletPoint
                  tituloBulletPoint
                  descripcionBulletPoint
                  textoEnlaceBulletPoint
                  enlaceBulletPoint
                }
                beneficios {
                  icono {
                    url
                    alt
                  }
                  tituloInterno
                  tituloPublico
                  descripcion
                  displayIndex
                }
                caracteristicas {
                  icono {
                    url
                    alt
                  }
                  tituloInterno
                  titulo
                  descripcion
                  displayIndex
                }
                promociones(where: {
                  OR: [
                    {AND: [
                      {fechaInicial_lte: $dateIn},
                      {fechaFinal_gte: $dateIn},
                    ]}
                    {AND: [
                      {fechaInicial: null},
                      {fechaFinal: null},
                    ]}
                  ]
                }) {
                  imgPromocion {
                    url
                    alt
                  }
                  friendlyId
                  linkPromocion
                  tituloPromocion
                }
                ptu {
                  cardImg {
                    url
                    alt
                  }
                  tyc {
                    url
                  }
                  cardTitulo
                  cardDescripcion
                  tipo
                  friendlyId
                  __typename
                }
                pda {
                  cardImg {
                    url
                    alt
                  }
                  tyC {
                    url
                  }
                  cardTitulo
                  cardDescripcion
                  tipo
                  friendlyId
                  __typename
                }
                ptm {
                  cardImg {
                    url
                    alt
                  }
                  tyC {
                    url
                  }
                  cardTitulo
                  cardDescripcion
                  tipo
                  friendlyId
                  versiondigital
                  __typename
                }
                pDs {
                  imagenProductoApp {
                    url
                    alt
                  }
                  imagenProductoAppResponsive {
                    url
                    alt
                  }
                  imagenProductoWeb {
                    url
                    alt
                  }
                  imagenProductoWebResponsive {
                    url
                    alt
                  }
                  tyC {
                    url
                  }
                  descripProductoApp
                  descripProductoWeb
                }
                dondeUsarTarjetas {
                  pda {
                    cardTitulo
                    cardImg {
                      url
                      alt
                    }
                    categoriaProductos {
                      categoria
                    }
                  }
                  ptu {
                    cardTitulo
                    cardImg {
                      url
                      alt
                    }
                    categoriaProductos {
                      categoria
                    }
                  }
                  ptm {
                    cardTitulo
                    cardImg {
                      url
                      alt
                    }
                    categoriaProductos {
                      categoria
                    }
                  }
                  giros(orderBy:orden_ASC) {
                    iconoBulletPoint {
                      url
                      alt
                    }
                    tituloBulletPoint
                    descripcionBulletPoint
                    orden
                  }
                  negociosAsociados(orderBy:orden_ASC) {
                    negocioLogo {
                      url
                      alt
                    }
                    orden
                  }
                }
              }
              metaDescripciones {
                nombreTag
                contenidoTag
                contenidoImagen {
                  url
                  alt
                }
              }
            }
          }
        `,
        variables: {
          vista,
          friendlyId,
          dateIn
        }
      })
      .valueChanges.pipe(map(result => result.data['paginas'][0]));
  }
}
