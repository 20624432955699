import { Component, Input, OnInit } from '@angular/core';
import { Assets } from '../../models/Assets';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  @Input() heroImg: string;
  @Input() heroRImg: string;
  @Input() nombrePublico: string;
  @Input() description: string;
  @Input() heroTextButton?: string;
  @Input() heroLinkTextButton?: string;
  @Input() hideLink = true;
  @Input() hideButton = true;
  @Input() pdfLink?: Assets;
  @Input() productsList?: string;
  @Input() tycName?: string;

  constructor() { }

  ngOnInit() {
    if (this.pdfLink === null) {
      this.pdfLink = { url: '', alt: '' };
    }
    if (this.pdfLink.url === null) {
      this.hideLink = false;
    }

    window.addEventListener('resize', () => {
      if (window.matchMedia('(min-width: 768px)').matches) {
        const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
        Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
          item['style'][item.getAttribute('data-property')] = `${leftSpace}px`;
        });
      } else {
        Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
          item['style'][item.getAttribute('data-property')] = `0`;
        });
      }
    });

    if (window.matchMedia('(min-width: 768px)').matches) {
      const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
      Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
        item['style'][item.getAttribute('data-property')] = `${leftSpace}px`;
      });
    }
  }
}
