import { Component } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

// Model
import { SliderHome } from 'src/app/base/models/slider-home';
// Query
import { SliderHomeQuery } from 'src/app/base/queries/slider-home.query';

@Component({
  selector: 'app-slider-home',
  templateUrl: './slider-home.component.html',
  styleUrls: ['./slider-home.component.scss']
})
export class SliderHomeComponent {

  public slides: SliderHome[];
  public slidesLength = 0;

  public activeItem: number;
  public itemsCount: number;

  // Interval
  private interval;

  constructor(private sliderHomeQuery: SliderHomeQuery, private app: AppService) {

    this.sliderHomeQuery.watch()
    .valueChanges
    .pipe(pluck('data', 'homeSliders'))
    .subscribe((data: SliderHome[]) => this.init(data));
  }

  private init(data: SliderHome[]): void {
    this.slides = data;
    this.slidesLength = data.length;
    this.activeItem = 0;
    this.itemsCount = this.slides.length - 1;
    this.initInterval();
    this.setListenerSwipe();
  }

  private setListenerSwipe(): void {
    const container = document.querySelector('.slider__content');

    let start = 0;
    let ends = 0;

    container.addEventListener('touchstart', (e) => {
      start = e['touches'][0].clientX;
    });

    container.addEventListener('touchend', (e) => {
      ends = e['changedTouches'][0].clientX;
      const swipeSize = 35;

      if (start > ends) {
        if ((start - ends) > swipeSize) {
          this.next();
        }
      } else if (start < ends) {
        if ((start - ends) < (swipeSize * -1)) {
          this.prev();
        }
      }
    });
  }

  public next(): void {
    if (this.activeItem + 1 > this.itemsCount) {
      this.activeItem = 0;
    } else {
      this.activeItem += 1;
    }

    this.removeInterval();
    this.initInterval();
  }

  public prev(): void {
    if (this.activeItem - 1 < 0) {
      this.activeItem = this.itemsCount;
    } else {
      this.activeItem -= 1;
    }

    this.removeInterval();
    this.initInterval();
  }

  public setIndex(index: number): void {
    this.activeItem = index;
  }

  private initInterval(): void {
    const intervalTime = 10; // Segundos
    this.interval = setInterval(() => {
      this.next();
    }, (intervalTime * 1000));
  }

  private removeInterval(): void {
    clearInterval(this.interval);
  }
}
