import { Component, OnInit, Input } from "@angular/core";
import { LandingPage } from "../../models/landing";

@Component({
  selector: "app-terms-conditions",
  templateUrl: "./terms-conditions.component.html",
  styleUrls: ["./terms-conditions.component.scss"]
})
export class TermsConditionsComponent implements OnInit {
  @Input() productsItem: LandingPage;

  constructor() {}

  ngOnInit() {}

  selectProduct(product) {
    let item;
    const temp = product;
    if (temp.pTM != null) {
      item = temp.pTM;
    }
    if (temp.pTU != null) {
      item = temp.pTU;
    }
    if (temp.pDA != null) {
      item = temp.pDA;
    }
    return item;
  }
}
