import { Component, Input } from '@angular/core';
// Models
import { Assets } from 'src/app/shared/models/Assets';

@Component({
  selector: 'app-pd-tab',
  templateUrl: './pd-tab.component.html',
  styleUrls: ['./pd-tab.component.scss']
})
export class PdTabComponent {

  @Input() descripProductoWeb: string;
  @Input() imagenProductoWeb: Assets;
  @Input() imagenProductoWebResponsive: Assets;
  @Input() descripProductoApp: string;
  @Input() imagenProductoApp: Assets;
  @Input() imagenProductoAppResponsive: Assets;

  constructor() {
  }

  activeTab(e: any, tab: any): void {
    this.removeActiveClass();

    tab.classList.add('active');
    e.target.classList.add('active');
  }

  removeActiveClass(): void {
    Array.from(document.querySelectorAll('.tab-content__item')).forEach(item => {
      if (item.classList.contains('active')) {
        item.classList.remove('active');
      }
    });

    Array.from(document.querySelectorAll('.tabs__btn')).forEach(item => {
      if (item.classList.contains('active')) {
        item.classList.remove('active');
      }
    });
  }
}
