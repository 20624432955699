import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// Models
import { Assistance } from 'src/app/business/models/PDA';
import { Post } from '../../models/post';

@Component({
  selector: 'app-md-card',
  templateUrl: './md-card.component.html',
  styleUrls: ['./md-card.component.scss']
})
export class MdCardComponent implements OnInit {

  @Input() card?: Assistance;

  @Input() post?: Post;

  @Input() blog?: Post;

  @Output() openModal: EventEmitter<{ modalInfo: any, isOpen: boolean }>;

  public Months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  constructor() {
    this.openModal = new EventEmitter();
  }

  ngOnInit() {
  }

  updateModal(e) {
    if (this.card) {
      const cardData = {
        nombrePublico: this.card.nombrePublico,
        cardDescription: this.card.cardDescription,
        cardImg: {
          url: this.card.cardImg.url
        },
        tyc: this.card.tyc,
        descripcionModal: this.card.descripcionModal
      }
      this.openModal.emit({ modalInfo: cardData, isOpen: true });
    } else if (this.post) {
      const postData = {
        nombrePublico: this.post.headerPost,
        cardDescription: this.post.excerptPost,
        cardImg: {
          url: this.post.imgTPost.url
        }
      }
      this.openModal.emit({ modalInfo: postData, isOpen: true });
    } else {
      const postData = {
        nombrePublico: this.post.headerPost,
        cardDescription: this.post.excerptPost,
        cardImg: {
          url: this.post.imgTPost.url
        }
      }
      this.openModal.emit({ modalInfo: postData, isOpen: true });
    }

  }

  getFormatDate(dateData: Date) {
    const postDate = new Date(dateData);
    return `${postDate.getDate()} ${this.Months[postDate.getMonth()]} ${postDate.getFullYear()}`;
  }
}
