import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
// Model
import { Post } from '../models/Post';

export interface AllPosts {
  lastPost: Post[];
  posts: Post[];
}

@Injectable({
  providedIn: 'root'
})
export class RecentPostsQuery extends Query<AllPosts, {}> {
  document = gql`
    query RecentPosts {
      lastPost: posts(first: 3, orderBy: datePost_DESC) {
        friendlyId
        imgPost {
          url
        }
        imgTPost {
          url
        }
        readingTPost
        categoriasPosts {
          categoria
        }
        textPost
        headerPost
        excerptPost
        datePost
      }
    }
  `;
}
