import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hubspot-form',
  templateUrl: './hubspot-form.component.html',
  styleUrls: ['./hubspot-form.component.scss']
})
export class HubspotFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.setScript();
  }
  private setScript(): void {
    const script = document.createElement('script');

    script.type = 'text/javascript';

    const scriptContent = document.createTextNode(`
      hbspt.forms.create({
        portalId: "5881411",
        formId: "1570a9a9-8a6e-4894-bc16-e6e6f8db7a78",
        target: "#form-hubspot"
      });
    `);

    script.appendChild(scriptContent);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
