import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CanonicalRoutesService } from 'src/app/shared/services/canonical-routes.service';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  constructor(private metatag: Meta, private canonical: CanonicalRoutesService) {
    this.metatag.addTag({ name: 'robots', content: 'noindex' });
  }

  ngOnInit(): void {
    this.canonical.setCanonicalRoute();
  }

  ngOnDestroy(): void {
    this.metatag.removeTag('name="robots"');
  }
}
