import { Component, OnInit, Input } from '@angular/core';
import { Seccion } from '../../models/pages';

@Component({
  selector: 'app-juntos-logramos',
  templateUrl: './juntos-logramos.component.html',
  styleUrls: ['./juntos-logramos.component.scss']
})
export class JuntosLogramosComponent implements OnInit {
  @Input() seccion: Seccion;
  constructor() {}

  ngOnInit() {}
}
