import { Promocion } from 'src/app/shared/models/Promotion';
import { Component, Input } from '@angular/core';

// Models
import { CommonProperties } from '../../models/CommonProperties';

@Component({
  selector: 'app-dot',
  templateUrl: './dot.component.html',
  styleUrls: ['./dot.component.scss']
})
export class DotComponent {

  @Input() promotion?: Promocion;
  @Input() data?: CommonProperties;
  @Input() linkText: string;
  @Input() link: string;

  constructor() { }

}
