import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
// Apollo
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
// import { persistCache } from 'apollo-cache-persist';

@NgModule({
  exports: [HttpClientModule, ApolloModule, HttpLinkModule],
  providers: []
})
export class GraphQLModule {
  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    const basic = setContext((op, ctx) => ({
      headers: new HttpHeaders().set('Accept', 'charset=utf-8'),
    }));

    const auth = setContext((operation, ctx) => ({
      headers: ctx.headers.append('Authorization', `Bearer ${ environment.graphToken }`)
    }));

    const link = ApolloLink.from([basic, auth, this.httpLink.create({ uri: `${ environment.uri }` })]);

    const cache = new InMemoryCache();

    // persistCache({
    //   cache,
    //   storage: window.localStorage,
    //   key: `si-vale-cache`,
    //   serialize: true
    // });

    this.apollo.create({
      link,
      cache
    });
  }
}
