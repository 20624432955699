import { Component, Input, OnInit } from '@angular/core';
import { Assets } from 'src/app/shared/models/Assets';

import { FAQsService } from 'src/app/shared/services/faqs.service';

@Component({
  selector: 'app-faq-shared',
  templateUrl: './faq-shared.component.html',
  styleUrls: ['./faq-shared.component.scss']
})
export class FaqSharedComponent implements OnInit {
  @Input() buttonLink: string;
  @Input() faqText: String;
  @Input() textLink: string;
  @Input() faqCategory: string;

  public questions: string[];

  constructor(private faqService: FAQsService) { }

  ngOnInit() {
    this.faqService.getFAQsByCategory(this.faqCategory).subscribe(data => {
      this.questions = data;
    });
  }
}
