import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';

// Model
import { SliderHome } from '../models/slider-home';

@Injectable({
  providedIn: 'root'
})
export class SliderHomeQuery extends Query<SliderHome, {}> {
  document = gql`
    query homeSliders {
      homeSliders(orderBy:posicion_ASC) {
        imagen {
          url
          alt
        }
        titulo
        descripcion
        textoLink
        linkSlider
        linkAppAndroid
        linkAppApple
        posicion
      }
    }
  `;
}
