import { Component } from '@angular/core';
import { pluck } from 'rxjs/operators';

// Models
import { Phone } from 'src/app/contact/models/Phone';
// Queries
import { PhoneQuery } from './../../../contact/queries/phone.query';

@Component({
  selector: 'app-contact-phone',
  templateUrl: './contact-phone.component.html',
  styleUrls: ['./contact-phone.component.scss']
})
export class ContactPhoneComponent {

  public contactPhones: Phone[];

  constructor(private phoneQuery: PhoneQuery) {
    this.phoneQuery
    .watch({
      tipo: 'Contacto'
    })
    .valueChanges
    .pipe(
      pluck('data', 'telefonoses')
    )
    .subscribe((data: Phone[]) => this.contactPhones = data);
  }

}
