import { Component, OnInit, Input } from '@angular/core';

// Models
import { LandingCaracteristicas } from '../../models/landing';

@Component({
  selector: 'app-characteristics-v2',
  templateUrl: './characteristics-v2.component.html',
  styleUrls: ['./characteristics-v2.component.scss']
})
export class CharacteristicsV2Component implements OnInit {

  @Input() characteristicItems: LandingCaracteristicas[];

  constructor() { }

  ngOnInit() {
  }

}
