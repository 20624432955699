import { Component, OnInit, OnChanges} from '@angular/core';
import {Router,NavigationEnd} from '@angular/router';
import * as $ from "jquery";
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  public navbarAppear = true;
  public footerAppear = true;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    let that = this
    let exceptions = ['cuenta-debito', 'compra-en-linea', 'cuenta-nueva', 'carga-documento']
    exceptions.map((item) => {
      if(window.location.pathname.includes(item)){
        that.navbarAppear = false; 
        that.footerAppear = false;
      }
    })
    this.setScript();
    var self = this;
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd)
        setTimeout(function(){self.doFadeInUpScroll();},1900);
    });
    setTimeout(function(){self.doFadeInUpScroll();},1900);
    
  }
  doFadeInUpScroll() {
    if(!$(".upgroup_footer").hasClass("animatable"))
      $(".upgroup_footer").addClass("animatable fadeInUp");
    var offset = $(window).scrollTop() + $(window).height() + 150,
    $animatables = $('.animatable');
		$animatables.each(function(i) {
       var $animatable = $(this);
       var animHeight = $animatable.height();
       if( animHeight > 250)
        animHeight = 200;
      else if(animHeight < 85)
        if($("body").height() - ($animatable.offset().top + animHeight) > 60)
          animHeight = 200;
      if (($animatable.offset().top + animHeight + 50) < offset) {
        if (!$animatable.hasClass('animate-in')) {
          $animatable.removeClass('animate-out').css('top', $animatable.css('top')).addClass('animate-in');
        }
			}
    });

  }

  private setScript(): void {
    const script = document.createElement('script');

    script.type = 'text/javascript';

    const scriptContent = document.createTextNode(`
      var _cdgcd = {
        header:"2R9VMXF5WL29",
        footer:"FQLEUAPN62RL",
        uri:"//www.headerfooter.charte-electronique-groupe.fr"
      };

      (function() {
        var head = document.getElementsByTagName("head")[0], lib;
        lib = document.createElement("script");
        lib.type = "text/javascript";
        lib.src = "//www.headerfooter.charte-electronique-groupe.fr/typo3conf/ext/cdgcd_headerfooter/Resources/Public/JavaScript/cdgcd_headerfooter.min.js";
        head.appendChild(lib);
      })();
    `);

    script.appendChild(scriptContent);
    if(this.footerAppear){
      console.log("footer")
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }
}
