import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterContentInit, ElementRef, ViewChild, Input } from '@angular/core';
// Models
import { NavbarSection } from '../../models/navbar-section.model';

@Component({
  selector: 'app-navbar-section',
  templateUrl: './navbar-section.component.html',
  styleUrls: ['./navbar-section.component.scss']
})
export class NavbarSectionComponent implements OnInit, AfterContentInit {

  @Input() options: NavbarSection[];

  @ViewChild('tabsParent') tabsParent: ElementRef;
  @ViewChild('tabs') tabs: ElementRef;

  public selectedOption: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.selectedOption = this.options[0].title;

    window.addEventListener('scroll', () => {
      if (this.tabsParent.nativeElement.getBoundingClientRect().top <= 110) {
        this.tabs.nativeElement.classList.add('sticky');
      } else {
        this.tabs.nativeElement.classList.remove('sticky');
      }

      Array.from(this.options).forEach(item => {
        this.setSectionActive(item);
      });
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.route.queryParams.subscribe((params) => {
        if (params && params['seccion']) {
          this.selectSection(params['seccion'], 170);
        }
      });
    }, 600);
  }

  private setSectionActive(item: NavbarSection): void {
    const el = document.getElementById(`${item.id}`);
    if (el === null) { return; }
    if (el.getBoundingClientRect().top <= 250) {
      this.selectedOption = item.title;
    }
  }

  private selectSection(id: string, extraSpace: number): void {
    const element = document.getElementById(`${id}`);
    if (element) {
      const offsetTopValue = element.offsetTop;
      window.scrollTo(0, (offsetTopValue - extraSpace));
    }
  }

  public dropdownSection(selectedOption: number): void {
    this.options.forEach(option => {
      if (option.value === selectedOption) {
        this.selectedOption = option.title;
        this.selectSection(option.id, 220);
      }
    });
  }

}
