import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Models
import { ContactFormRequest } from 'src/app/contact/models/contact-form-request';

// Services
import { ContactService } from 'src/app/contact/services/contact.service';
import { HelpersService } from '../../services/helpers.services';

@Component({
  selector: 'app-landing-contact',
  templateUrl: './landing-contact.component.html',
  styleUrls: ['./landing-contact.component.scss']
})
export class LandingContactComponent implements OnInit {

  public frmContact: FormGroup;

  constructor(private contactService: ContactService, private router: ActivatedRoute, private helpers: HelpersService) {
    this.frmContact = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
      message: new FormControl(''),
      'g-recaptcha-response': new FormControl(null, Validators.required),
      // Required fields not modified by form
      profile: new FormControl('empresa'),
      choice: new FormControl('adquirir'),
      products: new FormControl('', Validators.required),
      corporation: new FormControl(''),
    });

    this.router.params.subscribe(params => this.frmContact.get('products').setValue(params['friendlyId']));
  }

  ngOnInit() {
  }

  public sendForm(): void {
    this.contactService.sendForm(this.frmContact.value, 'Landing page')
    .then((result) => {
      
      this.frmContact.reset();
      this.helpers.redirectToTYPage();
    })
    .catch(() => {
      this.activeAlert(false);
    });
  }

  public activeAlert(success: boolean) {
    const className = 'active';
    const successAlert = document.querySelector('.alert-component-success');
    const errorAlert = document.querySelector('.alert-component-error');
    if (success) {
      successAlert.classList.add(className);
      setTimeout(() => successAlert.classList.remove(className), 3000);
    } else {
      errorAlert.classList.add(className);
      setTimeout(() => errorAlert.classList.remove(className), 3000);
    }
  }

}
