import { Component, OnInit, Input } from '@angular/core';

// Models
import { LandingBeneficios } from '../../models/landing';

@Component({
  selector: 'app-benefits-v2',
  templateUrl: './benefits-v2.component.html',
  styleUrls: ['./benefits-v2.component.scss']
})
export class BenefitsV2Component implements OnInit {

  @Input() benefitItems: LandingBeneficios[];

  constructor() { }

  ngOnInit() {
  }

}
