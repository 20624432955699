import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mayor-movilidad',
  templateUrl: './mayor-movilidad.component.html',
  styleUrls: ['./mayor-movilidad.component.scss']
})
export class MayorMovilidadComponent implements OnInit {
  @Input() tarjeta: String;

  constructor() {}

  ngOnInit() {}
}
