import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MarkdownModule } from "ngx-markdown";
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderConfig,
  SPINNER
} from "ngx-ui-loader";

// Modules
import { GraphQLModule } from "./graphql.module";
import { BaseModule } from "./base/base.module";
import { LandingModule } from "./landing/landing.module";
import { IntercomModule } from "ng-intercom";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";

// Services
import { AppService } from "./app.service";

// Recaptcha
import { NgxCaptchaModule } from "ngx-captcha";

// Routes
import { APP_ROUTES } from "./app.routes";

// Components
import { AppComponent } from "./app.component";
import { httpInterceptorProviders } from "./http-interceptors";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#F59100",
  fgsColor: "#F59100",
  fgsSize: 80,
  fgsType: SPINNER.foldingCube,
  hasProgressBar: false,
  overlayColor: "#f6f7f7",
  threshold: 1000
};

export function init(settingProvider: AppService) {
  return () => settingProvider.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    APP_ROUTES,
    GraphQLModule,
    HttpClientModule,
    NgxCaptchaModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    BaseModule,
    LandingModule,
    IntercomModule.forRoot({
      appId: "zltkiawp",
      updateOnRouterChange: true
    }),
    NgSelectModule,
    FormsModule
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [AppService],
      multi: true
    },
    AppService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
