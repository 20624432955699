import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-up-si-vale-app',
  templateUrl: './up-si-vale-app.component.html',
  styleUrls: ['./up-si-vale-app.component.scss']
})
export class UpSiValeAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
