import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class HelpersService {

  constructor(private router: Router) {}

  public pageTypes: Map<String, String> = new Map([
    ['afiliados', 'Afiliates'],
    ['', 'Home'],
    ['contacto', 'Contact'],
    ['faq', 'FAQ'],
    ['empresas', 'Business'],
    ['footer', 'Footer'],
    ['navbar', 'Navbar'],
    ['tarjetahabiente', 'Cardholder'],
    ['nosotros', 'Us']
  ]);

  public propertyOf = <TObj>(name: keyof TObj) => name;

  public getPageType(url: any): String {
    return this.pageTypes.get(url);
  }

  public redirectTo404() {
    this.router.navigate(['/404'], { skipLocationChange: true });
  }

  public redirectToTYPage() {
    this.router.navigate(['/gracias']);
  }
}
