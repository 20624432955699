import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FAQsService {
  private categoriaSource = new Subject<string>();
  categoriaAnnounced$ = this.categoriaSource.asObservable();

  private deseoSource = new Subject<string>();
  deseoAnnounced$ = this.deseoSource.asObservable();
  constructor(private apollo: Apollo) {}

  announceCategoria(categoria: string) {
    this.categoriaSource.next(categoria);
  }
  announceDeseo(deseo: string) {
    this.deseoSource.next(deseo);
  }

  public getFAQsByCategory(categoria: string) {
    return this.apollo
      .watchQuery({
        query: gql`
            query faqses($categoria: CatFaq) {
              faqses(where: { catFAQ: $categoria}) {
                id
                pregunta
              }
            }
          `,
          variables: {
            categoria
          }
      })
      .valueChanges.pipe(map(result => result.data['faqses']));
  }
}
