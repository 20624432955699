import { Component, OnInit, Input } from '@angular/core';
import { BulletPoint } from '../../models/pages';

@Component({
  selector: 'app-bullet-points',
  templateUrl: './bullet-points.component.html',
  styleUrls: ['./bullet-points.component.scss']
})
export class BulletPointsComponent implements OnInit {
  @Input() bulletPoints: BulletPoint[];

  constructor() {}

  ngOnInit() {}
}
