import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { Asistencia } from '../../models/pages';

@Component({
  selector: 'app-slider-assistance',
  templateUrl: './slider-assistance.component.html',
  styleUrls: ['./slider-assistance.component.scss']
})
export class SliderAssistanceComponent implements OnInit {

  @Input() asistencias: Asistencia[];

  // Pagination
  public startPage = 0;
  public endPage = 6;
  public perPage = 6;
  public dummyArray: Array<any>;
  public selectedIndex = 0;

  // Modal
  @ViewChild(ModalComponent)
  private modalComponent: ModalComponent;

  constructor() { }

  ngOnInit() {
    this.getPages();
  }

  public nextPage(): void {
    if (this.selectedIndex + 1 < this.dummyArray.length) {
      this.selectIndex(this.selectedIndex + 1);
    } else {
      this.selectIndex(0);
    }
  }

  public prevPage(): void {
    if (this.selectedIndex - 1 >= 0) {
      this.selectIndex(this.selectedIndex - 1);
    } else {
      this.selectIndex(this.dummyArray.length - 1);
    }
  }

  public getPages(): void {
    const pages = Math.ceil(
      this.asistencias.length / this.perPage
    );

    this.dummyArray = new Array(pages);
  }

  public selectIndex(index): void {
    this.selectedIndex = index;
    this.startPage = index * this.perPage;
    this.endPage = this.startPage + this.perPage;
  }

  openModalAction(e) {
    this.modalComponent.openModal(e.modalInfo);
  }

}
