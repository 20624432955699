import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

// Model
import { ContactForm } from './../models/contact-form';

declare var ga: any;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  public categoryProduct: string;

  constructor(private http: HttpClient) { }

  public sendForm(form: ContactForm, originPage?: string): Promise<any> {
    if (originPage) {
      this.createEventGA(originPage);
    }

    // const data = new HttpParams()
    //   .set('profile', form.profile)
    //   .set('choice', form.choice)
    //   .set('name', form.name)
    //   .set('corporation', form.corporation)
    //   .set('email', form.email)
    //   .set('phone', form.phone)
    //   .set('products', form.products)
    //   .set('message', form.message)
    //   .set('business', form.business)
    //   .set('whatsapp_number', form.whatsapp_number)
    //   .set('location', form.location)
    //   .set('employees', form.employees)
    //   .set('g-recaptcha-response', form['g-recaptcha-response']);

    console.log(form)

    return fetch(`${environment.contactoURL}/api/contact/`, {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
        "Accept": "*/*"
      },
      body: `
        {
          "typePerson": "${form.profile}",
          "typeInquiry": "${form.choice}",
          "name": "${form.name}",
          "company": "${form.corporation}",
          "email": "${form.email}",
          "phoneNumber": "${form.phone}",
          "inquiry": "${form.message}"
        }
      `
    })
    
    // return this.http
    //   // .post(`${environment.contactoURL}/mail/sivalemx`, data)
    //   .post(`http://localhost:8080/api/contact/`, data)
    //   .pipe(catchError(this.formatError));
  }

  private formatError(err): Observable<any> {
    return throwError(err.error);
  }

  private createEventGA(originPage: string) {
    ga('send', 'event', {
      eventCategory: 'Contacto',
      eventAction: 'Formulario completado',
      eventLabel: originPage
    });
  }

  public returnDropdownOptions() {
    const options = [
      {
        id: 1,
        name: 'soy una Empresa',
        value: 'soy una Empresa',
        tag: 'Empresas',
        options: [
          { id: 1, name: 'adquirir un producto', value: 'adquirir un producto' },
          { id: 2, name: 'resolver una duda', value: 'resolver una duda' },
          { id: 3, name: 'dejar un comentario o sugerencia', value: 'dejar un comentario o sugerencia' }
        ]
      },
      {
        id: 2,
        name: 'tengo una tarjeta Sí Vale',
        value: 'tengo una tarjeta Si Vale',
        tag: 'Tarjetahabientes',
        options: [
          { id: 1, name: 'resolver una duda', value: 'resolver una duda' },
          { id: 2, name: 'dejar un comentario o sugerencia', value: 'dejar un comentario o sugerencia' }
        ]
      },
      {
        id: 3,
        name: 'soy Afiliado Sí Vale',
        value: 'soy Afiliado Si Vale',
        tag: 'Afiliados',
        options: [
          {
            id: 1,
            name: 'recibir tarjetas Sí Vale (afiliarme)',
            value: 'recibir tarjetas Si Vale (afiliarme)'
          },
          {
            id: 2,
            name: 'publicidad en stickers para mi negocio',
            value: 'publicidad en stickers para mi negocio'
          },
          { id: 3, name: 'resolver una duda', value: 'resolver una duda' },
          { id: 4, name: 'dejar un comentario o sugerencia', value: 'dejar un comentario o sugerencia' }
        ]
      }
    ];

    return options;
  }

  public returnProductList(): { name: string, pageName: string }[] {
    const products = [
      { name: 'Vales o tarjeta de Despensa', pageName: 'Vales o tarjeta de Despensa' },
      { name: 'Vales o tarjeta de Combustible', pageName: 'Vales o tarjeta de Combustible' },
      { name: 'Tarjeta de Viáticos', pageName: 'Tarjeta de Viáticos' },
      { name: 'Tarjeta de Regalo e Incentivos', pageName: 'Tarjeta de Regalo e Incentivos' },
      { name: 'Vales o tarjeta de Restaurante', pageName: 'Vales o tarjeta de Restaurante' },
      { name: 'Vales o tarjeta para Vestimenta', pageName: 'Vales o tarjeta para Vestimenta' },
      { name: 'Tarjeta para Programas Sociales', pageName: 'Tarjeta para Programas Sociales' },
      { name: 'Programa de Bienestar para Empleados', pageName: 'Programa de Bienestar para Empleados' }
    ];

    return products;
  }

  public returnTitleForm() {
    const titles = [
      {
        id: 1,
        titles: [
          {
            title: 'Recibe más información para la contratación',
            desc: 'Compártenos tus datos y en breve te contactaremos.'
          },
          {
            title: '¿No encontraste lo que buscabas?',
            desc: 'Envíanos tu duda y en breve te contactaremos.'
          },
          {
            title: 'Queremos saber de tí',
            desc: 'Escríbenos tu mensaje y en breve un agente te contactará.'
          }
        ]
      },
      {
        id: 2,
        titles: [
          {
            title: '¿No encontraste lo que buscabas?',
            desc: 'Envíanos tu duda y en breve te contactaremos.'
          },
          {
            title: 'Queremos saber de tí',
            desc: 'Escríbenos tu mensaje y en breve un agente te contactará.'
          },
          {
            title: 'Recibe en tu comercio miles de tarjetas Up Sí Vale',
            desc:
              'Déjanos tus datos y en breve te contactaremos para iniciar el proceso de afiliación.'
          }
        ]
      },
      {
        id: 3,
        titles: [
          {
            title: 'Recibe en tu comercio miles de tarjetas Up Sí Vale',
            desc: 'Déjanos tus datos y en breve te contactaremos para iniciar el proceso de afiliación.'
          },
          {
            title: '¿A quién debemos mandarle la publicidad?',
            desc: 'Escríbenos tu mensaje y en breve un agente te contactará.'
          },
          {
            title: '¿No encontraste lo que buscabas?',
            desc: 'Envíanos tu duda y en breve te contactaremos.'
          },
          {
            title: 'Queremos saber de tí',
            desc: 'Escríbenos tu mensaje y en breve un agente te contactará.'
          }
        ]
      }
    ];

    return titles;
  }

  public getLocations() {
    const locations = [
      {
        id: 1,
        name: 'Aguascalientes',
        value: 'Aguascalientes'
      },
      {
          id: 2,
          name: 'Baja California',
          value: 'Baja California'
      },
      {
          id: 3,
          name: 'Baja California Sur',
          value: 'Baja California Sur'
      },
      {
          id: 4,
          name: 'Campeche',
          value: 'Campeche'
      },
      {
          id: 5,
          name: 'Chiapas',
          value: 'Chiapas'
      },
      {
          id: 6,
          name: 'Chihuahua',
          value: 'Chihuahua'
      },
      {
          id: 7,
          name: 'Ciudad de México',
          value: 'Ciudad de México'
      },
      {
          id: 8,
          name: 'Coahuila de Zaragoza',
          value: 'Coahuila de Zaragoza'
      },
      {
          id: 9,
          name: 'Colima',
          value: 'Colima'
      },
      {
          id: 10,
          name: 'Durango',
          value: 'Durango'
      },
      {
          id: 11,
          name: 'Guanajuato',
          value: 'Guanajuato'
      },
      {
          id: 12,
          name: 'Guerrero',
          value: 'Guerrero'
      },
      {
          id: 13,
          name: 'Hidalgo',
          value: 'Hidalgo'
      },
      {
          id: 14,
          name: 'Jalisco',
          value: 'Jalisco'
      },
      {
          id: 15,
          name: 'México',
          value: 'México'
      },
      {
          id: 16,
          name: 'Michoacán de Ocampo',
          value: 'Michoacán de Ocampo'
      },
      {
          id: 17,
          name: 'Morelos',
          value: 'Morelos'
      },
      {
          id: 18,
          name: 'Nayarit',
          value: 'Nayarit'
      },
      {
          id: 19,
          name: 'Nuevo León',
          value: 'Nuevo León'
      },
      {
          id: 20,
          name: 'Oaxaca',
          value: 'Oaxaca'
      },
      {
          id: 21,
          name: 'Puebla',
          value: 'Puebla'
      },
      {
          id: 22,
          name: 'Querétaro',
          value: 'Querétaro'
      },
      {
          id: 23,
          name: 'Quintana Roo',
          value: 'Quintana Roo'
      },
      {
          id: 24,
          name: 'San Luis Potosí',
          value: 'San Luis Potosí'
      },
      {
          id: 25,
          name: 'Sinaloa',
          value: 'Sinaloa'
      },
      {
          id: 26,
          name: 'Sonora',
          value: 'Sonora'
      },
      {
          id: 27,
          name: 'Tabasco',
          value: 'Tabasco'
      },
      {
          id: 28,
          name: 'Tamaulipas',
          value: 'Tamaulipas'
      },
      {
          id: 29,
          name: 'Tlaxcala',
          value: 'Tlaxcala'
      },
      {
          id: 30,
          name: 'Veracruz de Ignacio de la Llave',
          value: 'Veracruz de Ignacio de la Llave'
      },
      {
          id: 31,
          name: 'Yucatán',
          value: 'Yucatán'
      },
      {
          id: 32,
          name: 'Zacatecas',
          value: 'Zacatecas'
      }
    ];

    return locations;
  }

  public getEmployees() {
    const numbers = [
      {
        id: 1,
        name: '1 - 10',
        value: '1 - 10'
      },
      {
        id: 2,
        name: '11 - 50',
        value: '11 - 50'
      },
      {
        id: 3,
        name: '51 - 250',
        value: '51 - 250'
      },
      {
        id: 4,
        name: '+250',
        value: '+250'
      }
    ];

    return numbers;
  }

  public prefillParameters() {
    const param = [
      { pageName: 'Monedero +Despensa', categoria: 'Vales o tarjeta de Despensa' },
      { pageName: 'Tarjeta Restaurante', categoria: 'Vales o tarjeta de Restaurante' },
      { pageName: 'Tarjeta +Uniformes', categoria: 'Vales o tarjeta para Vestimenta' },
      { pageName: 'Up Beneficios Bienestar', categoria: 'Programa de Bienestar para Empleados' },
      { pageName: 'Monederos certificados de combustible', categoria: 'Vales o tarjeta de Combustible' },
      { pageName: 'Inteligas', categoria: 'Vales o tarjeta de Combustible' },
      { pageName: 'Tarjetas empresariales para gastos de viaje', categoria: 'Tarjeta de Viáticos' },
      { pageName: 'Up Business', categoria: 'Tarjeta de Viáticos' },
      { pageName: 'Tarjetas de Incentivos', categoria: 'Tarjeta de Regalo e Incentivos' },
      { pageName: 'Up Rewards', categoria: 'Tarjeta de Regalo e Incentivos' },
      { pageName: 'Tarjeta +Apoyos', categoria: 'Tarjeta para Programas Sociales' }
    ];

    return param;
  }
}

