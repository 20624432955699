import { Component, Input, OnInit, AfterContentInit, Output, EventEmitter } from '@angular/core';
// Models
import { Tarjeta } from './../../../business/models/tarjeta';
import { ProductIndexCard } from './../../../business/models/ProductIndexCard';

@Component({
  selector: 'app-cards-slider',
  templateUrl: './cards-slider.component.html',
  styleUrls: ['./cards-slider.component.scss']
})
export class CardsSliderComponent implements OnInit, AfterContentInit {
  @Input() data?: Tarjeta[];
  @Input() products?: ProductIndexCard[];
  @Output() dynamicText = new EventEmitter();

  public selectedCard = 0;
  public totalItems: number;

  // Pagination thumbnail cards
  public startPage = 0;
  public endPage = 3;

  public cardsTexts = [
    'Prestaciones para tus empleados',
    'Prestaciones para tus empleados',
    'Prestaciones para tus empleados',
    'Prestaciones para tus empleados',
    'Control de Recursos',
    'Control de Recursos',
    'Incentivos para tu equipo y clientes',
    'Incentivos para tu equipo y clientes',
    'Programas Sociales',
    'Programas Sociales'
  ];

  constructor() {}

  ngOnInit(): void {
    this.totalItems = this.data
      ? this.data.length - 1
      : this.products.length - 1;
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.setListenerSwipe();
    });
  }

  private setListenerSwipe(): void {
    const container = document.querySelector('.card-slider');
    const headerCard = document.querySelector('.flex-container');

    let start = 0;
    let ends = 0;

    headerCard.addEventListener('touchstart', (e) => {
      start = e['touches'][0].clientX;
    });

    headerCard.addEventListener('touchend', (e) => {
      ends = e['changedTouches'][0].clientX;
      const swipeSize = 35;

      if (start > ends) {
        if ((start - ends) > swipeSize) {
          this.next();
        }
      } else if (start < ends) {
        if ((start - ends) < (swipeSize * -1)) {
          this.prev();
        }
      }
    });

    container.addEventListener('touchstart', (e) => {
      start = e['touches'][0].clientX;
    });

    container.addEventListener('touchend', (e) => {
      ends = e['changedTouches'][0].clientX;
      const swipeSize = 35;

      if (start > ends) {
        if ((start - ends) > swipeSize) {
          this.next();
        }
      } else if (start < ends) {
        if ((start - ends) < (swipeSize * -1)) {
          this.prev();
        }
      }
    });
  }

  public selectCard(card: number): void {
    this.selectedCard = card;
    this.updateTextMobile(this.selectedCard);
  }

  public next(): void {
    if (this.selectedCard < this.totalItems) {
      this.selectedCard++;
    } else {
      this.selectedCard = 0;
    }
    this.updateTextMobile(this.selectedCard);
  }

  public prev(): void {
    if (this.selectedCard > 0) {
      this.selectedCard--;
    } else {
      this.selectedCard = this.totalItems;
    }
    this.updateTextMobile(this.selectedCard);
  }

  private updateTextMobile(selectedIndex): void {
    this.dynamicText.emit(this.cardsTexts[selectedIndex]);
  }
}
