import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { pluck } from 'rxjs/operators';

// Models
import { ProductIndexCard } from 'src/app/business/models/ProductIndexCard';
// Queries
import { AllPICsGQL } from 'src/app/business/queries/products.query';
// Services
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-products-related',
  templateUrl: './products-related.component.html',
  styleUrls: ['./products-related.component.scss']
})
export class ProductsRelatedComponent implements OnInit, OnChanges {

  @Input() categoryProduct: string;

  private allProducts: AllProducts;

  public products: ProductIndexCard[] = [];

  private appEnvironment: string;

  constructor(private AllIndexCardsQuery: AllPICsGQL, private app: AppService) {
    this.appEnvironment = this.app.configuration['GraphCMS']['environment'];
  }

  ngOnInit(): void {
    this.products = [];
    this.loadAllProducts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const categoryProductChange = changes.categoryProduct;
    if (categoryProductChange) {
      if (categoryProductChange.previousValue) {
        this.products = [];
        this.loadAllProducts();
      }
    }
  }

  private loadAllProducts(): void {
    this.AllIndexCardsQuery
    .fetch()
    .pipe(
      pluck('data')
    ).subscribe((data: AllProducts) => {
      this.allProducts = data;
      this.getRelatedProducts(this.categoryProduct);
    });
  }

  private getRelatedProducts(category: string): void {
    const cardData: CardData[] = this.cardData;

    if (!category) {
      const randomIndex = this.getRandom(this.cardData.length);
      category = cardData[randomIndex].category;
    } else {
      const product = category.split(',')[0];
      category = product;
    }

    const cardSelected = cardData.find((card) => card.category === category);

    cardSelected.related.forEach((cat: string) => {
      const cardCategory = cardData.find(c => c.category === cat);
      const card = this.allProducts[cardCategory.productType].find((typeName: ProductIndexCard) => typeName.cardTitulo === cardCategory.cardName);
      this.products.push(card);
    });
  }

  private get cardData(): CardData[] {
    const data = [
      { productType: 'pTUs', cardName: 'Monedero +Despensa', category: 'Vales o tarjeta de Despensa', related: [
        'Vales o tarjeta de Restaurante',
        'Vales o tarjeta de Combustible',
        'Tarjeta de Regalo e Incentivos'
      ] },
      { productType: 'pTUs', cardName: 'Tarjeta Restaurante', category: 'Vales o tarjeta de Restaurante', related: [
        'Vales o tarjeta de Despensa',
        'Vales o tarjeta de Combustible',
        'Tarjeta de Regalo e Incentivos'
      ] },
      { productType: 'pTUs', cardName: 'Tarjeta +Uniformes', category: 'Vales o tarjeta para Vestimenta', related: [
        'Vales o tarjeta de Despensa',
        'Vales o tarjeta de Restaurante',
        'Vales o tarjeta de Combustible'
      ] },
      { productType: 'pDAs', cardName: 'Up Beneficios Bienestar', category: 'Programa de Bienestar para Empleados', related: [
        'Vales o tarjeta de Despensa',
        'Vales o tarjeta de Restaurante',
        'Tarjeta de Regalo e Incentivos'
      ] },
      { productType: 'pTMs', cardName: 'Soluciones para el control de gasto de combustible', category: 'Vales o tarjeta de Combustible', related: [
        'Tarjeta de Viáticos',
        'Vales o tarjeta de Despensa'
      ] },
      { productType: 'pTMs', cardName: 'Soluciones para el control de los gastos de viaje', category: 'Tarjeta de Viáticos', related: [
        'Vales o tarjeta de Combustible',
        'Vales o tarjeta de Despensa'
      ] },
      { productType: 'pTMs', cardName: 'Tarjeta de Incentivos', category: 'Tarjeta de Regalo e Incentivos', related: [
        'Vales o tarjeta de Despensa',
        'Vales o tarjeta de Restaurante',
        'Vales o tarjeta de Combustible'
      ] },
      { productType: 'pTUs', cardName: 'Up Rewards', category: 'Tarjeta de Regalo e Incentivos', related: [
        'Vales o tarjeta de Despensa',
        'Vales o tarjeta de Restaurante',
        'Vales o tarjeta de Combustible'
      ] },
      { productType: 'pTUs', cardName: 'Tarjeta +Apoyos', category: 'Tarjeta para Programas Sociales', related: [
        'Vales o tarjeta de Combustible',
        'Vales o tarjeta de Despensa'
      ] }
    ];

    return data;
  }

  private getRandom(max: number): number {
    return Math.floor(Math.random() * max);
  }
}

interface AllProducts {
  pTUs: ProductIndexCard[],
  pDAs: ProductIndexCard[],
  pTMs: ProductIndexCard[]
}

interface CardData {
  productType: string;
  cardName: string, category: string;
  related: string[];
}
