import { Component } from '@angular/core';
import { AssetsService } from '../../services/assets.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public logo: string;
  public footerImg: string;

  constructor(private assets: AssetsService) {
    this.assets.getAsset('logo_si_vale.svg').subscribe(data => this.logo = data[0].url);
    this.assets.getAsset('sprite.png').subscribe(data => this.footerImg = data[0].url);
  }

}
