import { Component, OnInit, OnDestroy } from '@angular/core';
import { pluck } from 'rxjs/operators';
// Models
// Queries
import { RecentPostsQuery } from 'src/app/blog/queries/recent_posts.query';
// Services
import { AppService } from 'src/app/app.service';
import { AssetsService } from 'src/app/shared/services/assets.service';
import { PageService } from 'src/app/shared/services/page.service';
import { MetatagsService } from 'src/app/shared/services/metatags.service';
import { CanonicalRoutesService } from 'src/app/shared/services/canonical-routes.service';
// Utils
import { setPageTitle } from 'src/app/shared/utilities/utils';
import { Vistas } from 'src/app/shared/enums/vistas';
import { Pagina } from 'src/app/shared/models/pages';

interface AssetHome {
  fileName: string;
  url: string;
  alt: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  debugger;
  public pageData: Pagina;

  public scrollImg: AssetHome;
  // Allies
  public carnet: AssetHome;
  public masterCard: AssetHome;
  public visa: AssetHome;
  // App
  public cellMobileImg: AssetHome;
  public cellDesktopImg: AssetHome;
  public appleImg: AssetHome;
  public androImg: AssetHome;

  whyUpSiVale = [
    {
      class: '',
      value: 16,
      title: 'Años de experiencia en México'
    },
    {
      class: 'over-plus',
      value: 'Más de 9,500',
      title: 'Empresas clientes'
    },
    {
      class: '',
      value: '4 M',
      title: 'De Tarjetahabientes'
    },
    {
      class: '',
      value: '85 M',
      title: 'Transacciones operadas'
    },
    {
      class: '',
      value: '36%',
      title: 'De participación en el mercado'
    },
    {
      class: '',
      value: '32',
      title: 'Sucursales'
    }
  ];

  // Slider navigation
  public indicators: number[];
  public startPage = 0;
  public endPage = 2;
  public activeItem = 0;

  public posts;

  private appEnvironment: string;

  constructor(
    private assetsService: AssetsService,
    private recentPost: RecentPostsQuery,
    private pageService: PageService,
    private app: AppService,
    private metaTags: MetatagsService,
    private canonical: CanonicalRoutesService
  ) {
    this.appEnvironment = this.app.configuration['GraphCMS']['environment'];

    this.assetsService.getViewAssets('Home').subscribe((assets: AssetHome[]) => {
      assets.forEach((asset: AssetHome) => {
        // Hero
        if (asset.fileName === 'Indicator@1x.svg') {
          this.scrollImg = asset;
        } // Mouse scroll indicator
        // Allies
        if (asset.fileName === 'Carnet@1x.png') {
          this.carnet = asset;
        }
        if (asset.fileName === 'MasterCard@1x.png') {
          this.masterCard = asset;
        }
        if (asset.fileName === 'Visa_Inc._logo@1x.svg') {
          this.visa = asset;
        }
        // App
        if (asset.fileName === 'cell_app_Movil@1x.svg') {
          this.cellMobileImg = asset;
        } // Mobile
        if (asset.fileName === 'Cell_app_Desktop_ilus@1x.svg') {
          this.cellDesktopImg = asset;
        } // Desktop
        if (asset.fileName === 'google-play-badge.svg') {
          this.androImg = asset;
        }
        if (asset.fileName === 'App_Store_Badge.svg') {
          this.appleImg = asset;
        }
      });
    });

    this.pageService
    .getPage(
      Vistas.Home,
      'Home'    
      )
    .subscribe((data: Pagina) => {
      this.pageData = data;
      this.metaTags.setMetaTags(data.metaDescripciones);
    });

    this.canonical.setCanonicalRoute();

    const titleElement = document.querySelector('title');
    setPageTitle(titleElement, 'Home');
  }

  ngOnInit(): void {
    this.recentPost
    .watch()
    .valueChanges.pipe(pluck('data'))
    .subscribe(res => this.posts = res);
  }

  ngOnDestroy(): void {
    this.metaTags.removeMetaTags(this.pageData.metaDescripciones);
  }
}
