import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

// Models
import { ProductIndexCard } from '../models/ProductIndexCard';

export interface PICsQueryResult {
  ptu: [ProductIndexCard];
  pda: [ProductIndexCard];
  ptm: [ProductIndexCard];
}

@Injectable({
  providedIn: 'root'
})
export class AllPICsGQL extends Query<PICsQueryResult, {}> {
  document = gql`
    query businessQuery {
      pTUs {
        cardImg {
          url
          alt
        }
        tyc {
          url
        }
        cardTitulo
        cardDescripcion
        tipo
        friendlyId
        __typename
      }
      pDAs {
        cardImg {
          url
          alt
        }
        tyC {
          url
        }
        cardTitulo
        cardDescripcion
        tipo
        friendlyId
        __typename
      }
      pTMs {
        cardImg {
          url
          alt
        }
        tyC {
          url
        }
        cardTitulo
        cardDescripcion
        tipo
        friendlyId
        versiondigital
        __typename
      }
    }
  `;
}
