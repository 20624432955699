import { Component, Input, OnInit } from '@angular/core';

import { Promocion } from 'src/app/shared/models/Promotion';

@Component({
  selector: 'app-promotion-slider',
  templateUrl: './promotion-slider.component.html',
  styleUrls: ['./promotion-slider.component.scss']
})
export class PromotionSliderComponent implements OnInit {
  @Input() promotions: Promocion[];

  public activeItem: number;
  public itemsCount: number;
  private interval;
  constructor() {}

  ngOnInit() {
    this.activeItem = 0;
    this.itemsCount = this.promotions.length - 1;

    this.setListenerSwipe();
  }

  private setListenerSwipe(): void {
    const container = document.querySelector('.slider__wrapper_promotions');

    let start = 0;
    let ends = 0;

    container.addEventListener('touchstart', (e) => {
      start = e['touches'][0].clientX;
    });

    container.addEventListener('touchend', (e) => {
      ends = e['changedTouches'][0].clientX;
      const swipeSize = 35;

      if (start > ends) {
        if ((start - ends) > swipeSize) {
          this.next();
        }
      } else if (start < ends) {
        if ((start - ends) < (swipeSize * -1)) {
          this.prev();
        }
      }
    });
  }

  public async next() {
    if (this.activeItem + 1 > this.itemsCount) {
      this.activeItem = 0;
    } else {
      this.activeItem += 1;
    }
    this.removeInterval();
    this.initInterval();
  }

  public async prev() {
    if (this.activeItem - 1 < 0) {
      this.activeItem = this.itemsCount;
    } else {
      this.activeItem -= 1;
    }
    this.removeInterval();
    this.initInterval();
  }

  public setIndex(index: number): void {
    this.activeItem = index;
  }

  private initInterval(): void {
    const intervalTime = 10; // Segundos
    this.interval = setInterval(() => {
      this.next();
    }, (intervalTime * 1000));
  }

  private removeInterval(): void {
    clearInterval(this.interval);
  }


  public addAnimSlider(class_name,anim_name,delete_classes){
    const elements = document.getElementsByClassName(class_name);
    for(let i = 0; i < elements.length; i++) {
      const item = (elements.item(i) as HTMLElement);
      item.classList.remove(anim_name);
      delete_classes.forEach(element => {
        item.classList.remove(element);
      });
      item.classList.add(anim_name);
    }
  }
}
