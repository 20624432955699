import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pluck, map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

// Enums
import { VersionLandingPage } from 'src/app/landing/enums/tipoLanding';

// Models
import { LandingPage, HeroLanding, LandingSeccion } from './models/landing';

// Queries
import { LandingQuery } from './queries/landing.query';

// Services
import { HelpersService } from './../shared/services/helpers.services';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public pageData: LandingPage;

  public heroData: HeroLanding;

  // sections generic
  public bulletPointsSection: LandingSeccion;
  // sections V1
  public beneficiosSection: LandingSeccion;
  // sections V2
  public caracteristicasSection: LandingSeccion;
  public beneficiosSectionV2: LandingSeccion;
  // V2
  public isV2: boolean;

  private appEnvironment: string;

  constructor(private router: ActivatedRoute, private helpers: HelpersService, private landinQuery: LandingQuery, private app: AppService) {
    this.appEnvironment = this.app.configuration['GraphCMS']['environment'];

    this.router.params.subscribe(params => {
      this.landinQuery.watch({
        'friendlyId': this.helpers.propertyOf<LandingPage>(params['friendlyId'])
      })
      .valueChanges
      .pipe(
        pluck('data', 'landingPageses'),
        map(d => d[0])
      )
      .subscribe((data: LandingPage) => {
        if (!data) { this.helpers.redirectTo404(); return; }

        this.pageData = data;
        this.heroData = {
          heroTitle: this.pageData.heroTitle,
          heroDescription: this.pageData.heroDescription,
          heroImg: this.pageData.heroImg,
          heroRImg: this.pageData.heroRImg
        };
        this.isV2 = VersionLandingPage.V2 === this.pageData.versionLandingPage;

        this.beneficiosSection = data.secciones.find(s => !s['seccionImg'] && s['beneficios'].length > 0);
        // V1
        this.bulletPointsSection = this.filterSection('bulletPoints');
        // V2
        this.beneficiosSectionV2 = this.filterSectionData('seccionImg');
        this.caracteristicasSection = this.filterSection('caracteristicas');
      });
    });
  }

  ngOnInit(): void {}

  private filterSection(section: string): LandingSeccion {
    return this.pageData.secciones.find(item => item[section].length > 0);
  }

  private filterSectionData(section: string): LandingSeccion {
    return this.pageData.secciones.find(item => item[section]);
  }
}
