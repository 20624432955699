import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';

// Model
import { Phone } from './../models/Phone';


@Injectable({
  providedIn: 'root'
})
export class PhoneQuery extends Query<Phone, {}> {
  document = gql`
    query Telefonos($tipo: CategoriaTelefono) {
      telefonoses(where: { tipoDeTelefono: $tipo }, orderBy: posicion_ASC) {
        posicion
        tituloCategoria
        tipoDeTelefono
        contenido
      }
    }
  `;
}
