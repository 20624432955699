import { Pipe, PipeTransform } from '@angular/core';

/* Usage:

On any component using the SharedModule

<ul>
    <li *ngFor="let element of (element | sortElements:'propName')"></li>
</ul>
*/

@Pipe({
  name: 'sortElements'
})
export class SortElementsPipe implements PipeTransform {
  transform(elements: [any], criteria: String): any {
    return elements.sort(
      (firstElement, nextElement) =>
        firstElement[`${criteria}`] - nextElement[`${criteria}`]
    );
  }
}
