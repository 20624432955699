// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  uri: 'https://api-us-east-1.graphcms.com/v2/cjnvzsigw1rsb01ijd6mwn5ie/master',
  graphToken:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MTA5OTE2NjEsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NqbnZ6c2lndzFyc2IwMWlqZDZtd241aWUvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMmYxZTljYzItM2E0NC00Mjk4LThlNzAtZTlhNGRhOTE3MWJjIiwianRpIjoiY2trMnV1OW4weWF5OTAxeG02Y3IyOGV1ZCJ9.C06Z215YgK6U8LkpBJEFe6x1VrUf8Ca7ehDab2JL2tV-cOmrmGyqvRuvSvQl5TLYxhG_3yuG3AVVvL1gt5VqQjwIPJu-aZNkmotIyFkATgMeC4PpAZLb8H0vw_C0OxhaWeaWfmNgrZUypjPKEscw2rG2E6yPuIaRuINZ_5VRFPwUV0fp8id-nmKaur5f7TiBUai-_s_MKBwJoiAN_nBhXdg8DYDKMQLmxxf5MQVFuxtjNwhOrTsfxF2KB1NGI8uf2lt15hKsNcflUGRrAuWpn6D6Wn56qhh32QElhVXc3SEKERmzPH6H6mix0wRW6Fiq-wpmC-KztEJ9lzvFALghGh6TSoGuK8ZoSlpqOsLMBFXz2avFkhhxwzb0gcTA-RnLj4hHpNeEFI7fvoEf5hD46y54Xcyy0PBmxk6WRMfD7-_Kv73sP0jKFl-icwEirbcMcUqZg8QCajXkfWVskgRwhOjkJ9XdzGBwITxwfLF8xDczh0BiPZlophucGncnCQWaGhREgDAfq5POJTHUYQPajr_ywUXBpUiysh40Cknd-pLnli3kkV0bQMe1kTuviVl68YzjK_sc68gzO44nqTzg7wwuW4qQb5JFG9LC0xCCvCBm5Fg_L5iV3osiv5nokeaPDGtqRyn7U-Gs8Ine9TUBwpysdwpzoTHWAmxSBLXt2lM',
  afiliadosURL: 'https://afiliados.sivale.mx',
  contactoURL: 'https://sivale-od-qa-api.azurewebsites.net',
  RECAPTCHA_SITE_KEY_V2: '6LfO75UUAAAAALUxHBq7kG3lpse4HL0HC-6FYO5-',
  RECAPTCHA_SITE_KEY_V3: '6Lf9NqYUAAAAAKF4-X02sOykMbd5XejqNCOTZQi_',
  cotizacionURL: 'https://sivale-mx-od-qa-api.azurewebsites.net/api/cotizacion',
  RECAPTCHA_LANGUAGE: 'es',
  sentryKey: '68b8869c44a34929b5bb835976b40285',
  sentryProjectID: '1419213',
  graphStatus: ['DRAFT', 'PUBLISHED']
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
