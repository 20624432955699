import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CanonicalRoutesService {

  constructor(@Inject(DOCUMENT) private dom) { }

  public setCanonicalRoute(): void {
    if (this.hasCanonicalRoute()) {
      const prevCanonical = this.dom.querySelector('link[rel="canonical"]');
      prevCanonical.setAttribute('href', this.dom.URL);
    } else {
      this.createCanonical();
    }
  }

  public createCanonical(): void {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  public hasCanonicalRoute(): boolean {
    const prevCanonical = this.dom.querySelector('link[rel="canonical"]');
    if (prevCanonical != null) {
      return true;
    }
    return false;
  }
}
