import { Component, Input } from '@angular/core';
import { ProductIndexCard } from 'src/app/business/models/ProductIndexCard';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() card: ProductIndexCard;

  constructor() {
  }

}
