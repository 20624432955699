const pagesMap = new Map([
  ['Contact', 'Contáctanos'],
  ['Us', 'Nosotros'],
  ['Cardholder', 'Tarjetahabientes'],
  ['Business', 'Empresas'],
  ['Afiliates', 'Afiliados'],
  ['Home', 'Inicio']
]);

function setPageTitle(titleElement: HTMLTitleElement, title: string): void {
  if (pagesMap.has(title)) {
    titleElement.textContent = `${pagesMap.get(
      title
    )} | Up Sí Vale: Haciendo cada día mejor`;
  } else {
    titleElement.textContent = `${title} | Up Sí Vale: Haciendo cada día mejor`;
  }
}

export { setPageTitle };
