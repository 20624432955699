import { Routes, RouterModule } from '@angular/router';

// Components
import { LandingComponent } from './landing.component';

const routes: Routes = [
  { path: ':friendlyId', component: LandingComponent },
];

export const LANDING_ROUTES = RouterModule.forChild(routes);
