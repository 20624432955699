import { Component, Input, OnInit } from '@angular/core';
// Models
import { Breadcrumbs } from './../../models/breadcrumbs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() pages: Breadcrumbs[];
  @Input() isVersionDigital?: boolean;

  constructor() {
  }

  ngOnInit(): void {
    window.addEventListener('resize', () => {
      if (window.matchMedia('(min-width: 768px)').matches) {
        const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
        Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
          item['style'][item.getAttribute('data-property')] = `${leftSpace}px`;
        });
      } else {
        Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
          item['style'][item.getAttribute('data-property')] = `0`;
        });
      }
    });

    if (window.matchMedia('(min-width: 768px)').matches) {
      const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
      Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
        item['style'][item.getAttribute('data-property')] = `${leftSpace}px`;
      });
    }
  }
}
