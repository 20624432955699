import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact-message',
  templateUrl: './contact-message.component.html',
  styleUrls: ['./contact-message.component.scss']
})
export class ContactMessageComponent implements OnInit {
  @Input() message?: String;

  constructor() {}

  ngOnInit() {}
}
