import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';

// Routes
import { LANDING_ROUTES } from './landing.routes';

// Modules
import { SharedModule } from '../shared/shared.module';

// Components
import { LandingComponent } from './landing.component';
import { BenefitsV1Component } from './components/benefits-v1/benefits-v1.component';
import { BenefitsV2Component } from './components/benefits-v2/benefits-v2.component';
import { CharacteristicsV2Component } from './components/characteristics-v2/characteristics-v2.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { LandingBulletpointsComponent } from './components/landing-bulletpoints/landing-bulletpoints.component';

@NgModule({
  declarations: [
    LandingComponent,
    BenefitsV1Component,
    BenefitsV2Component,
    CharacteristicsV2Component,
    TermsConditionsComponent,
    LandingBulletpointsComponent
  ],
  imports: [CommonModule, MarkdownModule, SharedModule, LANDING_ROUTES],
  exports: [],
  providers: []
})
export class LandingModule {}
