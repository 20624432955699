import { Component, OnInit, Input } from "@angular/core";
import { LandingSeccion } from "../../models/landing";

@Component({
  selector: "app-landing-bulletpoints",
  templateUrl: "./landing-bulletpoints.component.html",
  styleUrls: ["./landing-bulletpoints.component.scss"]
})
export class LandingBulletpointsComponent implements OnInit {
  @Input() Bullets: LandingSeccion;

  constructor() {}

  ngOnInit() {}
}
