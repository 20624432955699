import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FAQsService } from 'src/app/shared/services/faqs.service';

@Component({
  selector: 'app-faq-contact',
  templateUrl: './faq-contact.component.html',
  styleUrls: ['./faq-contact.component.scss']
})
export class FaqContactComponent implements OnInit, AfterViewInit {
  @Input() faqText: string;
  @Input() faqCategory: string;

  public questions: string[];

  constructor(private faqService: FAQsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadQuestions();
  }

  private showAnim(): void {
    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);
  }

  private loadQuestions(): void {
    this.faqService.getFAQsByCategory(this.faqCategory).subscribe(data => {
      this.questions = data;
      this.showAnim();
    });
  }
}
