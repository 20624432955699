import { Component, Input, ViewChild, ElementRef } from '@angular/core';
// Models
import { GenericModalData } from '../../models/generic-modal-data.model';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() modalData: GenericModalData;
  @Input() isOpen: boolean;

  @ViewChild('modalInfo') modal: ElementRef;

  constructor() { }

  openModal(data: GenericModalData) {
    this.modalData = data;
    this.modal.nativeElement.classList.add('open');
  }

  closeModal() {
    this.modal.nativeElement.classList.remove('open');
  }

  clickModal(event) {
    if (event.target === this.modal.nativeElement) {
      this.closeModal();
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 27 || event.key === 'Escape') {
      this.closeModal();
    }
  }
}
