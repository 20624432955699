export enum Vistas {
  Home = 'Home',
  Contact = 'Contact',
  FAQ = 'FAQ',
  Business = 'Business',
  Footer = 'Footer',
  Navbar = 'Navbar',
  Afiliates = 'Afiliates',
  Cardholder = 'Cardholder',
  Us = 'Us',
  Blog = 'Blog'
}
