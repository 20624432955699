import { Routes, RouterModule } from '@angular/router';

// Components
import { BaseComponent } from './base.component';
import { HomeComponent } from './components/home.component';
import { NotFoundComponent } from '../error-page/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'covid-protect',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://inter.mx/seguro-covid-protect-si-vale/'
      }
  },
  {
    path: 'pago-servicios',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://pagaqui.com.mx/sivale_servicios.aspx?key=1F9B6D5C05F1C369'
      }
  },
  {
    path: 'micredito',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://info.sivale.mx/credito-empresarial-pyme?utm_campaign=Cr%C3%A9dito%20S%C3%AD%20Vale&utm_source=Brochure-Coahuila'
      }
  },
  {
    path: 'vales-en-linea',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://www.sivale.mx/compra-en-linea?utm_campaign=Onboarding%20digital&utm_source=ClienteAliato&utm_content=OBD-Aliato'
      }
  },
  {
    path: 'oficinaencasa',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://info.sivale.mx/tarjeta-digital-homeoffice?utm_campaign=Incentivos&utm_source=Webinar-WEBTalks-oficinaencasa-23julio&utm_medium=Webinar-WEBTalks-oficinaencasa-23julio&utm_term=Webinar-WEBTalks-oficinaencasa-23julio&utm_content=Webinar-WEBTalks-oficinaencasa-23julio'
      }
  },
  {
    path: 'cotizatuseguro',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: '/'
      }
  },
  {
    path: 'indexdespensa',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://wls-webprod.sivale.mx/sivale/marketing/index-despensa-terminosycondiciones.pdf'
      }
  },
  {
    path: 'compra-vales-en-linea',
      component: BaseComponent,
      resolve: {
          url: 'externalUrlRedirectResolver'
      },
      data: {
          externalUrl: 'https://www.sivale.mx/compra-en-linea?utm_campaign=Onboarding%20digital&utm_source=SalesNavigator-Ejecutivo&utm_medium=SalesNavigator-Ejecutivo&utm_term=SalesNavigator-Ejecutivo&utm_content=SalesNavigator-Ejecutivo'
      }
  },
  {
    path:'terminos-y-condiciones-2-meses-sin-costo',
    component: BaseComponent,
    resolve : {
      url : 'assetUrlResolver'
    },
    data:{
      fileName : '/assets/Terminos y Condiciones Contrata ahora y obten 2 meses sin Comision.pdf'
    }
  },
  {
    path:'recompensa-garantizada',
    component: BaseComponent,
    resolve : {
      url : 'assetUrlResolver'
    },
    data:{
      fileName : '/assets/Terminos_y_Condiciones_Recompensa_Garantizada_B2C.pdf'
    }
  },
  { path: '', component: BaseComponent, children: [
    { path: '', component: HomeComponent },
    { path: '', loadChildren: '../business/business.module#BusinessModule' },
    { path: '', loadChildren: '../cardholder/cardholder.module#CardholderModule' },
    { path: '', loadChildren: '../us/us.module#USModule' },
    { path: '', loadChildren: '../faq/faq.module#FAQModule' },
    { path: '', loadChildren: '../contact/contact.module#ContactModule' },
    { path: '', loadChildren: '../blog/blog.module#BlogModule' },
    { path: '', loadChildren: '../thank-you-page/thank-you-page.module#ThankYouPageModule' },
    { path: '', loadChildren: '../pdf-download/pdf-download.module#PdfDownloadModule' },
    { path: '', loadChildren: '../klar/klar.module#KlarModule' },
    { path: '', loadChildren: '../redirect/redirect.module#RedirectModule' },
    { path: '404', component: NotFoundComponent }
  ] },
];

export const BASE_ROUTES = RouterModule.forChild(routes);
