import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

// Models
import { HeroLanding, LandingPage } from 'src/app/landing/models/landing';
import { ContactFormRequest } from 'src/app/contact/models/contact-form-request';

// Services
import { ContactService } from 'src/app/contact/services/contact.service';
import { HelpersService } from '../../services/helpers.services';

// reCaptcha v3
// declare var grecaptcha: any;
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-hero-landing',
  templateUrl: './hero-landing.component.html',
  styleUrls: ['./hero-landing.component.scss']
})
export class HeroLandingComponent implements OnInit {
  @Input() heroData: HeroLanding;
  @Input() versionLanding: boolean;
  @Input() pageData: LandingPage;

  public frmContact: FormGroup;
  // Pagination step form
  public actualPage = 1;
  public startPage = 1;
  public endPage = 2;

  // First dropdown
  public ImList = [
    { id: 1, name: 'Soy una empresa interesada', value: 'empresa' },
    { id: 2, name: 'Soy una empresa cliente', value: 'empresa' }
  ];
  public ImSelected = this.ImList[0];
  // Dropdown location
  public locationReset = { id: 0, name: 'Selecciona Estado', value: '' };
  public locationSelected = this.locationReset;
  public locationList = [];
  // Dropdown employee
  public employeeReset = { id: 0, name: 'Número de empleados', value: '' };
  public employeeSelected = this.employeeReset;
  public employeeList;

  constructor(private contactService: ContactService, private router: ActivatedRoute, private helpers: HelpersService, private grecaptcha: ReCaptchaV3Service) {
    this.frmContact = new FormGroup({
      // 1st step
      profile: new FormControl(`${this.ImSelected.value}`, Validators.required),
      message: new FormControl(`${this.ImSelected.name}`),
      corporation: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      employees: new FormControl('', Validators.required),
      // 2nd step
      name: new FormControl('', [Validators.required]),
      whatsapp_number: new FormControl('', [
        Validators.minLength(5)
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(5)
      ]),
      'g-recaptcha-response': new FormControl(null, Validators.required),
      // Required fields not modified by form
      choice: new FormControl('adquirir'),
      products: new FormControl('', Validators.required)
    });

    this.locationList = this.contactService.getLocations();
    this.employeeList = this.contactService.getEmployees();

    this.router.params.subscribe(params => this.frmContact.get('products').setValue(params['friendlyId']));
  }

  ngOnInit() {
    window.addEventListener('resize', () => {
      if (window.matchMedia('(min-width: 768px)').matches) {
        const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
        Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
          item['style'][item.getAttribute('data-property')] = `${leftSpace}px`;
        });
      } else {
        Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
          item['style'][item.getAttribute('data-property')] = `0`;
        });
      }
    });

    if (window.matchMedia('(min-width: 768px)').matches) {
      const leftSpace = document.querySelector('.container').getBoundingClientRect().left + 25;
      Array.from(document.querySelectorAll('.auto-property-value')).forEach((item: Element) => {
        item['style'][item.getAttribute('data-property')] = `${leftSpace}px`;
      });
    }
  }

  public getCaptchaValidation(): void {
    this.grecaptcha.execute(
      environment.RECAPTCHA_SITE_KEY_V3,
      'landing',
      (token) => this.frmContact.get('g-recaptcha-response').setValue(token),
      { useGlobalDomain: false }
    );
  }

  public sendForm(): void {
    this.contactService.sendForm(this.frmContact.value, 'Landing page')
    .then((result) => {
      this.frmContact.reset();
      this.helpers.redirectToTYPage();
     
    })
    .catch(() => {
      this.activeAlert(false);
    });
  }

  public activeAlert(success: boolean) {
    const className = 'active';
    const successAlert = document.querySelector('.alert__success');
    const errorAlert = document.querySelector('.alert__error');
    if (success) {
      successAlert.classList.add(className);
      setTimeout(() => successAlert.classList.remove(className), 3000);
    } else {
      errorAlert.classList.add(className);
      setTimeout(() => errorAlert.classList.remove(className), 3000);
    }
  }

  public validateStep(): boolean {
    let firstStep = [
      'profile',
      'corporation',
      'location',
      'employees',
    ];

    let isValid = true;

    for(let i = 0; i < firstStep.length; i++) {
      const field = this.frmContact.get(firstStep[i]).valid;
      if (!field) {
        isValid = false;
      }
    }

    return !isValid;
  }

  public prevStep() {
    this.actualPage--;
  }

  public nextStep() {
    this.actualPage++;

    this.getCaptchaValidation();
  }

  public selectProduct(product) {
    let item;
    const temp = product;
    if (temp.pTM != null) {
      item = temp.pTM;
    }
    if (temp.pTU != null) {
      item = temp.pTU;
    }
    if (temp.pDA != null) {
      item = temp.pDA;
    }
    return item;
  }

  public openDropdown(el: Element, event?: MouseEvent): void {
    el.classList.toggle('open');
    el.nextElementSibling.classList.toggle('open');

    document.addEventListener('click', (e) => {
      // tslint:disable-next-line:triple-equals
      if (e.target != el) {
        el.classList.remove('open');
        el.nextElementSibling.classList.remove('open');
      }
    });

    if (event) {
      event.stopPropagation();
    }
  }

  public imSelectedOption(el: Element, option: { id: number; name: string; value: string }): void {
    this.ImSelected = option;
    this.openDropdown(el);

    this.frmContact.get('profile').setValue(this.ImSelected.value);
    this.frmContact.get('message').setValue(this.ImSelected.name);
  }

  public locationSelectedOption(el: Element, option: { id: number; name: string; value: string }): void {
    this.locationSelected = option;
    this.openDropdown(el);

    this.frmContact.get('location').setValue(this.locationSelected.value);
  }

  public employeesSelectedOption(el: Element, option: { id: number; name: string; value: string }): void {
    this.employeeSelected = option;
    this.openDropdown(el);

    this.frmContact.get('employees').setValue(this.employeeSelected.value);
  }
}
