import { Component, OnInit, Input } from '@angular/core';

// Models
import { CommonProperties } from 'src/app/shared/models/CommonProperties';

@Component({
  selector: 'app-advantages-benefits-characteristics',
  templateUrl: './advantages-benefits-characteristics.component.html',
  styleUrls: ['./advantages-benefits-characteristics.component.scss']
})
export class AdvantagesBenefitsCharacteristicsComponent implements OnInit {

  @Input() abcItems: CommonProperties[];

  constructor() { }

  ngOnInit() {
  }

}
