import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-balance-button',
  templateUrl: './balance-button.component.html',
  styleUrls: ['./balance-button.component.scss']
})
export class BalanceButtonComponent implements OnInit {
  @ViewChild('btnSaldo') btnSaldo: ElementRef;

  public urlBalance: SafeUrl;
  public appUrl = 'appsivale://com.sivale.tarjetas';
  public iosAppUrl = 'appsivale://';
  public androidUrl = 'intent://#Intent;package=com.sivale.android;scheme=com.sivale.android;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.sivale.android;end;';
  public iosUrl = 'https://itunes.apple.com/us/app/up-s%C3%AD-vale/id1155198473?mt=8';
  public desktopUrl = 'https://th.sivale.mx/';

  constructor(private sanitizer: DomSanitizer) {
    this.setURLBalance();
  }

  ngOnInit() { }

  private isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    return (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']));
  }

  private isIos() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    return ((/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']));
  }

  private getMobileUrl(): string {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      return this.androidUrl;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return this.iosUrl;
    }
  }

  handleClick(event: Event): void {
    if (this.isMobile()) {
      if (this.isIos()) {
        this.urlBalance =  this.sanitizer.bypassSecurityTrustUrl(this.getMobileUrl());
      } else {
        event.preventDefault();
        const self = this;
        const appWindow = window.open( this.appUrl);
        setTimeout(function() {
          if (appWindow) {
            appWindow.location.href =  self.getMobileUrl();
          }
        }, 1000);
      }
    }
  }

  closeNotification(e): void {
    this.btnSaldo.nativeElement.style.opacity = 0;
    this.btnSaldo.nativeElement.addEventListener('transitionend', () => {
      this.btnSaldo.nativeElement.style.display = 'none';
    });
  }

  private setURLBalance(): void {
    if (this.isMobile() ) {
      this.urlBalance =  this.sanitizer.bypassSecurityTrustUrl(this.appUrl);
      return;
    }
    this.urlBalance = this.sanitizer.bypassSecurityTrustUrl(this.desktopUrl);
  }
}
