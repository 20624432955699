import { Injectable } from '@angular/core';
// Necessary to work with GraphQL
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private apollo: Apollo) { }

  public getAsset(fileName: string) {
    return this.apollo.watchQuery({
      query: gql`
        query assets {
          assets(where: {
            fileName: "${ fileName }"
          }) {
            url
            alt
          }
        }
      `
    })
    .valueChanges.pipe(map(result => result.data['assets']));
  }

  public getViewAssets(view) {
    return this.apollo.watchQuery({
      query: gql`
        query assets($vista: Vistas) {
          assets (where: { vista: $vista }) {
            fileName
            url
            alt
          }
        }
      `,
      variables: {
        vista: view
      }
    })
    .valueChanges.pipe(map(result => result.data['assets']));
  }
}
