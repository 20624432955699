import { Query } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
// Models
import { LandingPage } from '../models/landing';

@Injectable({
  providedIn: 'root'
})
export class LandingQuery extends Query<LandingPage, {}> {
  document = gql`
    query landingsQuery($friendlyId: String!) {
      landingPageses(where: { friendlyId: $friendlyId }) {
        heroTitle
        heroDescription
        heroImg {
          url
        }
        heroRImg {
          url
        }
        versionLandingPage
        pTM {
          cardImg {
            url
          }
          tyC {
            url
          }
          nombrePublico
          tarjetas {
            imagen {
              url
            }
            nombre
          }
        }
        pTU {
          cardImg {
            url
          }
          tyc {
            url
          }
          nombrePublico
        }
        pDA {
          cardImg {
            url
          }
          tyC {
            url
          }
          nombre
        }
        secciones {
          idSeccion
          tituloSeccion
          descripcionSeccion
          seccionImg {
            url
          }
          beneficios {
            icono {
              url
            }
            tituloInterno
            tituloPublico
            descripcion
            displayIndex
          }
          bulletPoints {
            iconoBulletPoint {
              url
            }
            tituloBulletPoint
            textoBulletPoint
            descripcionBulletPoint
          }
          caracteristicas {
            icono {
              url
            }
            descripcion
          }
        }
      }
    }
  `;
}
