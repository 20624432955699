import { AssetsService } from "../../services/assets.service";
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentInit,
  Input
} from "@angular/core";
import { Button } from "protractor";

@Component({
  selector: "app-navbar-cotizador",
  templateUrl: "./navbar-cotizador.component.html",
  styleUrls: ["./navbar-cotizador.component.scss"]
})
export class NavbarCotizadorComponent implements OnInit, AfterContentInit {

  public hrefLink = "/compra-en-linea";
  @Input() href;
  @Input() idElement;

  @ViewChild("navbarContainer") navbarContainer: ElementRef;
  @ViewChild("navbar") navbar: ElementRef;

  public logo: string;
  public callServiceOpen = false;

  constructor(private assets: AssetsService) {
    this.assets
      .getAsset("Sv_logo1.svg")
      .subscribe(data => (this.logo = data[0].url));
  }

  ngOnInit(): void {
    window.addEventListener("scroll", () => {
      if (this.navbarContainer.nativeElement.getBoundingClientRect().top <= 0) {
        this.navbar.nativeElement.classList.add("sticky");
      } else {
        this.navbar.nativeElement.classList.remove("sticky");
      }
    });


    if (this.href) { this.hrefLink = this.href }
    if (this.idElement) {
      let that = this;
      (document.getElementById('navbar__logo')).addEventListener('click', (e) => {
        e.preventDefault()
        let cords = (document.getElementById(that.idElement)).getBoundingClientRect()
        console.log(cords)
        let yCord = cords.top
        window.scrollTo(0, yCord + window.pageYOffset - 60)
      })
    }
  }

  ngAfterContentInit(): void {
    const resizeEvent = () => {
      const leftSpace =
        document.querySelector(".container").getBoundingClientRect().left + 25;
      document.documentElement.style.setProperty(
        "--left-margin",
        `${leftSpace}px`
      );
    };

    window.addEventListener("resize", () => {
      resizeEvent();
    });

    resizeEvent();
  }

  openMenu() {
    this.updateActiveElement();

    document.body.style.overflow = "hidden";
  }

  closeMenu() {
    this.updateActiveElement();

    document.body.style.overflow = "auto";
  }

  updateActiveElement() {
    let margin = 0;
    let activeIndex = 0;
    let baseCenter = 0;

    Array.from(document.querySelectorAll(".navbar__link")).forEach(
      (item, i) => {
        if (item.classList.contains("active")) {
          margin = 18;
          activeIndex = i + 1;
          baseCenter = (margin * 3) / 2;

          for (let j = 1; j < activeIndex; j++) {
            baseCenter = baseCenter + margin * 2;
          }
        }
      }
    );
  }
}
