import { MarkdownModule } from 'ngx-markdown';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// Recaptcha
import { NgxCaptchaModule } from 'ngx-captcha';

// Components
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { CardComponent } from './components/card/card.component';
import { DotComponent } from './components/dot/dot.component';
import { ContactMessageComponent } from './components/contact-message/contact-message.component';
import { HeroComponent } from './components/hero/hero.component';
import { MdCardComponent } from './components/md-card/md-card.component';
import { ModalComponent } from './components/modal/modal.component';
import { CardsSliderComponent } from './components/cards-slider/cards-slider.component';
import { PdTabComponent } from './components/pd-tab/pd-tab.component';
import { PromotionSliderComponent } from './components/promotion-slider/promotion-slider.component';
import { FaqSharedComponent } from './components/faq-shared/faq-shared.component';
import { NavbarSectionComponent } from './components/navbar-section/navbar-section.component';
import { DropdownNavbarSectionComponent } from './components/dropdown-navbar-section/dropdown-navbar-section.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UpSiValeAppComponent } from './components/up-si-vale-app/up-si-vale-app.component';
import { AdvantagesBenefitsCharacteristicsComponent } from './components/advantages-benefits-characteristics/advantages-benefits-characteristics.component';
import { HeroLandingComponent } from './components/hero-landing/hero-landing.component';
import { LandingContactComponent } from './components/landing-contact/landing-contact.component';
import { NavbarLandingComponent } from './components/navbar-landing/navbar-landing.component';
import { FooterLandingComponent } from './components/footer-landing/footer-landing.component';
import { MayorMovilidadComponent } from './components/mayor-movilidad/mayor-movilidad.component';
import { JuntosLogramosComponent } from './components/juntos-logramos/juntos-logramos.component';
import { BulletPointsComponent } from './components/bullet-points/bullet-points.component';
import { SortElementsPipe } from './pipes/sortElements.pipe';
import { SliderAssistanceComponent } from './components/slider-assistance/slider-assistance.component';
import { ContactPhoneComponent } from './components/contact-phone/contact-phone.component';
import { SliderHomeComponent } from './components/slider-home/slider-home.component';
import { BalanceButtonComponent } from './components/balance-button/balance-button.component';
import { ProductsRelatedComponent } from './components/products-related/products-related.component';
import { FaqContactComponent } from './components/faq-contact/faq-contact.component';
import { HubspotFormComponent } from './components/hubspot-form/hubspot-form.component';
import { NavbarCotizadorComponent } from './components/navbar-cotizador/navbar-cotizador.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    CardComponent,
    DotComponent,
    ContactMessageComponent,
    HeroComponent,
    MdCardComponent,
    ModalComponent,
    CardsSliderComponent,
    PdTabComponent,
    PromotionSliderComponent,
    FaqSharedComponent,
    NavbarSectionComponent,
    DropdownNavbarSectionComponent,
    OurTeamComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    UpSiValeAppComponent,
    AdvantagesBenefitsCharacteristicsComponent,
    NavbarLandingComponent,
    HeroLandingComponent,
    LandingContactComponent,
    NavbarLandingComponent,
    FooterLandingComponent,
    SortElementsPipe,
    MayorMovilidadComponent,
    JuntosLogramosComponent,
    BulletPointsComponent,
    FooterLandingComponent,
    SliderAssistanceComponent,
    ContactPhoneComponent,
    SliderHomeComponent,
    BalanceButtonComponent,
    ProductsRelatedComponent,
    FaqContactComponent,
    HubspotFormComponent,
    NavbarCotizadorComponent
  ],
  imports: [CommonModule, RouterModule, NgxCaptchaModule, ReactiveFormsModule, MarkdownModule.forChild()],
  exports: [
    NavbarComponent,
    FooterComponent,
    CardComponent,
    DotComponent,
    ContactMessageComponent,
    HeroComponent,
    MdCardComponent,
    ModalComponent,
    CardsSliderComponent,
    PdTabComponent,
    PromotionSliderComponent,
    FaqSharedComponent,
    NavbarSectionComponent,
    DropdownNavbarSectionComponent,
    OurTeamComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    UpSiValeAppComponent,
    AdvantagesBenefitsCharacteristicsComponent,
    NavbarLandingComponent,
    HeroLandingComponent,
    LandingContactComponent,
    NavbarLandingComponent,
    FooterLandingComponent,
    SortElementsPipe,
    MayorMovilidadComponent,
    JuntosLogramosComponent,
    BulletPointsComponent,
    FooterLandingComponent,
    SliderAssistanceComponent,
    ContactPhoneComponent,
    FaqContactComponent,
    SliderHomeComponent,
    BalanceButtonComponent,
    ProductsRelatedComponent,
    HubspotFormComponent,
    NavbarCotizadorComponent
  ],
  providers: [
  ]
})
export class SharedModule { }
