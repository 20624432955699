import { AssetsService } from '../../services/assets.service';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterContentInit
} from '@angular/core';

@Component({
  selector: 'app-navbar-landing',
  templateUrl: './navbar-landing.component.html',
  styleUrls: ['./navbar-landing.component.scss']
})
export class NavbarLandingComponent implements AfterContentInit {
  @ViewChild('menu') navbar: ElementRef;
  @ViewChild('nav') nav: ElementRef;

  public logo: string;
  public callServiceOpen = false;

  constructor(private assets: AssetsService) {
    this.assets
      .getAsset('Sv_logo1.svg')
      .subscribe(data => (this.logo = data[0].url));
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterContentInit(): void {
    const resizeEvent = () => {
      const leftSpace =
        document.querySelector('.container').getBoundingClientRect().left + 25;
      document.documentElement.style.setProperty(
        '--left-margin',
        `${leftSpace}px`
      );
    };
    window.addEventListener('resize', () => {
      resizeEvent();
    });

    resizeEvent();
  }

  openMenu() {
    this.updateActiveElement();
    this.navbar.nativeElement.classList.add('open');
    document.body.style.overflow = 'hidden';
  }

  closeMenu() {
    this.updateActiveElement();
    this.navbar.nativeElement.classList.remove('open');
    document.body.style.overflow = 'auto';
  }

  updateActiveElement() {
    let margin = 0;
    let activeIndex = 0;
    let baseCenter = 0;

    Array.from(document.querySelectorAll('.navbar__link')).forEach(
      (item, i) => {
        if (item.classList.contains('active')) {
          margin = 18;
          activeIndex = i + 1;
          baseCenter = (margin * 3) / 2;

          for (let j = 1; j < activeIndex; j++) {
            baseCenter = baseCenter + margin * 2;
          }
        }
      }
    );

    this.nav.nativeElement.style.setProperty(
      '--triangule-top',
      `${baseCenter - margin}px`
    );
    this.nav.nativeElement.style.setProperty(
      '--triangule-center',
      `${baseCenter}px`
    );
    this.nav.nativeElement.style.setProperty(
      '--triangule-bottom',
      `${baseCenter + margin}px`
    );
  }
}
